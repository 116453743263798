import { Component, DestroyRef, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet, Event } from '@angular/router';
import { registerSvgIcons } from './shared/static/svgIcons';
import { Subject, filter, takeUntil, tap } from 'rxjs';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, LoaderComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  loading = true;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private destroyRef: DestroyRef,
  ) {
    registerSvgIcons(this.matIconRegistry, this.domSanitizer);
    const bodyTag = document.body;
    bodyTag.classList.add('loaded');
    this.stopAppLoader();
  }

   /**
   * Stop the HTML Loader on navigationEnd
   */
   private stopAppLoader() {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        // we only want one NavigationEnd  event to filter through
        filter((event: Event) => event instanceof NavigationEnd),
        tap(() => {
          this.loading = false;
        }),
      ).subscribe();
  }
}