import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Resident, ResidentService } from 'src/app/shared/services/resident/resident.service';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard {
  constructor(
    private authService: AuthService,
    private appState: AppStateService,
    private residentsService: ResidentService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // We have to use isLoggedIn from Authservice, because this guard is executed in parallel with
    // the AuthGuard to finish and it will consider the session to be logged out.
    return this.authService.isLoggedIn()
      .pipe(
        switchMap(authState => {
          if (authState?.isLoggedIn) {
            return this.residentsService.getResident({
              residentId: this.appState.get('currentResidentId'),
              facilityId: this.appState.get('currentFacilityId')
            }).pipe(
              map(() => {
                if (!this.authService.hasGroupAccess(route.data.groups)) {
                  return this.router.parseUrl('/');
                }
                const currentResident = this.appState.get<Resident>('currentResident')
                // WHEN WE HAVE MORE FAMILY MEMBERS PER RESIDENT, WE HAVe TO REVISE THIS.
                const rootPath = route.url[0];
                if (currentResident.family[0].confirmed && rootPath.path === 'orientation') {
                  return this.router.parseUrl(`/lifestory`);
                }
                return true;
              })
            )
          }
          return of(false);
        }),
      )
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // We have to use isLoggedIn from Authservice, because this guard is executed in parallel with
    // the AuthGuard to finish and it will consider the session to be logged out.

    return this.authService.isLoggedIn()
      .pipe(
        map(authState => {
          if (authState.isLoggedIn) {
            if (!this.authService.hasGroupAccess(route.parent.data.groups)) {
              return this.router.parseUrl('/');
            }
            return true;
          }
          return false;
        })
      )
  }
}

