import { Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { filter, map } from 'rxjs';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { ContentItemCollection } from 'src/app/shared/services/content/content.service';

@Component({
  selector: 'app-expired',
  imports: [AsyncPipe],
  templateUrl: './expired.component.html',
})
export class ExpiredComponent {

  content$ = this.appState.get$<ContentItemCollection>('contentItemCollection')
    .pipe(
      filter(collection => !!collection),
      map(collection => {
        return collection['magic-link-expired'];
      })
    )
  constructor(
    private appState: AppStateService,
  ) {}
}
