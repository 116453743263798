import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isLoggedIn()
      .pipe(
        map(authState => {
          // if NOT logged in and the route
          if (authState && !authState.isLoggedIn) {
            return this.router.parseUrl('/logged-out');
          // Auto logout if someone goes to the root
          }
          return true;
        }));
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isLoggedIn()
      .pipe(
        map(authState => {
          // if NOT logged in and the route we want to go to is not part of auth
          if (authState && !authState.isLoggedIn ) {
          // if (authState && !authState.isLoggedIn && state.url.search(/auth\/login/) === -1) {
            return this.router.parseUrl('/logged-out');
          }
          return true;
        })
      );
  }
}

